'use client';

import { Unstable_Grid2 as Grid, Box, Typography, Container } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { BenefitsBanner } from '@/globalComponents/PartDisplayPage/_components/BenefitsBanner';
import { PartDisplayParams } from '@/shared/classes/SPSPageComponentParams/PartDisplayParams';
import { TenantAlias } from '@/shared/tenants-alias-map';
import { PartDisplayDataResponse, PartRelevantPartsDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
import { PartBasicInfo } from './_components/PartBasicInfo';
import { PartBreadcrumbs } from './_components/PartBreadcrumbs';
import { PartCondition } from './_components/PartCondition';
import { PartDivider } from './_components/PartDivider';
import { MemoizedPartFeatures, getHasProductDetails } from './_components/PartFeatures';
import { PartLongDescription } from './_components/PartLongDescription';
import { PartPhotoDialog } from './_components/PartPhotoDialog';
import { PartRelatedDocuments } from './_components/PartRelatedDocuments';
import { PartRelevantPartsCarousel } from './_components/PartRelevantPartsCarousel';
import { TrustBox } from './_components/TrustBox/TrustBox';
type PartDisplayPageProps = PartDisplayDataResponse & {
  canonicalUrl: string;
  relevantParts: PartRelevantPartsDataResponse['parts'];
  partDisplayParams: PartDisplayParams;
  tenantAlias: TenantAlias;
};
export const PartDisplayPage = ({
  part,
  partCategoriesToRoot,
  relevantParts,
  canonicalUrl,
  basicParametricDatas,
  featureInstances,
  documents,
  publicQtyAndPrice,
  partDisplayParams,
  tenantAlias
}: PartDisplayPageProps) => {
  const isMobile = useIsMobile();
  const partCategory = partCategoriesToRoot.at(-1);
  const hasProductDetails = getHasProductDetails(featureInstances, basicParametricDatas);
  return <>
      <Container sx={{
      pt: {
        xs: 1.5,
        md: 3,
        minHeight: 0
      }
    }} data-sentry-element="Container" data-sentry-source-file="PartDisplayPage.tsx">
        <Grid container columnSpacing={[0, 0, 3]} data-sentry-element="Grid" data-sentry-source-file="PartDisplayPage.tsx">
          <Grid xs={12} md={8} component="section" data-sentry-element="Grid" data-sentry-source-file="PartDisplayPage.tsx">
            <Box sx={{
            pb: {
              xs: 1.5,
              md: 3
            },
            pt: {
              xs: 1,
              md: 0
            }
          }} data-sentry-element="Box" data-sentry-source-file="PartDisplayPage.tsx">
              <PartBreadcrumbs partID={part.id} partNumber={part.partNumber} partManufacturerName={part.manufacturer.name} partCategoriesToRoot={partCategoriesToRoot} data-sentry-element="PartBreadcrumbs" data-sentry-source-file="PartDisplayPage.tsx" />
            </Box>
            <Grid container columns={8} spacing={3} xs={12} md={8} mb={1} data-sentry-element="Grid" data-sentry-source-file="PartDisplayPage.tsx">
              <Grid xs={8} py={1} data-sentry-element="Grid" data-sentry-source-file="PartDisplayPage.tsx">
                <Typography variant="h2" component="h1" sx={{
                fontSize: [26, 36],
                lineHeight: 1.3,
                mt: [1, 1, 0]
              }} data-sentry-element="Typography" data-sentry-source-file="PartDisplayPage.tsx">
                  {part.partNumber} {part.manufacturer.name}{' '}
                  {partCategory?.name}
                </Typography>
              </Grid>
              <Grid xs={8} md={3} data-sentry-element="Grid" data-sentry-source-file="PartDisplayPage.tsx">
                <PartPhotoDialog part={part} tenantAlias={tenantAlias} data-sentry-element="PartPhotoDialog" data-sentry-source-file="PartDisplayPage.tsx" />
              </Grid>
              <Grid sx={{
              display: 'flex',
              flexDirection: 'column'
            }} xs={8} md={5} pt={[0, 0, 1.5]} data-sentry-element="Grid" data-sentry-source-file="PartDisplayPage.tsx">
                <PartBasicInfo partCategoriesToRoot={partCategoriesToRoot} part={part} publicQtyAndPrice={publicQtyAndPrice} partDisplayParams={partDisplayParams} data-sentry-element="PartBasicInfo" data-sentry-source-file="PartDisplayPage.tsx" />
              </Grid>
            </Grid>
            {hasProductDetails && <>
                <MemoizedPartFeatures featureInstances={featureInstances} basicParametricDatas={basicParametricDatas} partAbsoluteUrl={canonicalUrl} />
                <PartDivider />
              </>}
            <PartCondition data-sentry-element="PartCondition" data-sentry-source-file="PartDisplayPage.tsx" />
            <PartDivider data-sentry-element="PartDivider" data-sentry-source-file="PartDisplayPage.tsx" />
            {part.longDescription?.length > 0 && <>
                <PartLongDescription longDescription={part.longDescription} partNumber={part.partNumber} />
                <PartDivider />
              </>}
            <PartRelatedDocuments part={part} documents={documents} data-sentry-element="PartRelatedDocuments" data-sentry-source-file="PartDisplayPage.tsx" />
          </Grid>
          {!isMobile && <Grid xs={12} md={4} data-cy="StickySidebar" component="section">
              <TrustBox partDisplayParams={partDisplayParams} part={part} publicQtyAndPrice={publicQtyAndPrice} />
            </Grid>}
        </Grid>
      </Container>
      {relevantParts.length > 0 && <PartRelevantPartsCarousel parts={relevantParts} partAbsoluteUrl={canonicalUrl} tenantAlias={tenantAlias} />}
      <BenefitsBanner data-sentry-element="BenefitsBanner" data-sentry-source-file="PartDisplayPage.tsx" />
    </>;
};